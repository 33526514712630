import React, {useState, useMemo, useContext, useEffect} from "react";
import "./SearchResults.css";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";
import {useTranslation} from "react-i18next";
import CustomPagination from './CustomPagination';
import Modal from "./Modal";
import {Helmet} from "react-helmet";
import ModalWithInput from "./ModalWithInput";
import PriceComponent from "./PriceComponent";
import _ from "lodash";
import {AuthContext} from "./AuthContext";

const SearchResults = () => {

    const {isAuthenticated} = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const itemsPerPage = 10;

    const [isLoading, setIsLoading] = useState(false);
    const [savedCars, setSavedCars] = useState(new Set());

    const [cars, setCars] = useState(location.state.cars);

    useEffect(() => {
        console.log('isAuthenticated changed from SearchResult:', isAuthenticated);
    }, [isAuthenticated]);

    function goToDetails(vin) {
        setIsLoading(true);
        axios
            .get('/api/getCarDetails', {
                params: {
                    vin: vin
                }
            })
            .then(response =>
                navigate("/carDetails", {state: response.data})
            ).then(response => {
            setIsLoading(false)
        })
            .catch(error => {
                setOpenModal(true)
                setIsLoading(false)
            });
    }

    const {t} = useTranslation();

    const [currentPage, setCurrentPage] = useState(1);
    const [openModal, setOpenModal] = useState(false);
    const [openAlertModal, setOpenAlertModal] = useState(false);
    const [openModalWithInput, setOpenModalWithInput] = useState(false);
    const [interestedCarVin, setInterestedCarVin] = useState(null);
    const [selectedTrim, setSelectedTrim] = useState(null)
    const [selectedMinYear, setSelectedMinYear] = useState(parseInt(location.state.minYear));
    const [selectedMaxYear, setSelectedMaxYear] = useState(parseInt(location.state.maxYear));
    const [selectedMinOdo, setSelectedMinOdo] = useState(parseInt(location.state.minOdo));
    const [selectedMaxOdo, setSelectedMaxOdo] = useState(parseInt(location.state.maxOdo));
    const [dailDeals, setDailDeals] = useState(parseInt(location.state.dailDeals));


    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * itemsPerPage;
        const lastPageIndex = firstPageIndex + itemsPerPage;
        return cars.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, cars]);

    function openModalWithInputFunction(vin) {
        setOpenModalWithInput(true)
        setInterestedCarVin(vin);
    }

    const onChangeOfTrim = (event) => {
        setSelectedTrim(event.target.value);
        setIsLoading(true)
        const ipAddress = localStorage.getItem('userIP');
        let url = '/api/getCarListUsingApi?brand=' + location.state.brand + '&series=' + location.state.series + '&selectedMinYear=' + location.state.minYear + '&selectedMaxYear=' + location.state.maxYear + '&selectedMinOdo=' + location.state.minOdo + '&selectedMaxOdo=' + location.state.maxOdo + '&resultsPerPage=25' + '&preset=0' + '&dailyDeals=' + location.state.dailyDeals + '&trimId=' + location.state.trimsIdMap[event.target.value] + '&ipAddress=' + ipAddress
        axios
            .get(url)
            .then(response =>
                setCars(response.data.cars)
            ).then(response => {
            setIsLoading(false)
        })
            .catch(error => {
                setOpenModal(true)
                setIsLoading(false)
            });
    };

    const minYearChange = event => {
        setSelectedMinYear(event.target.value);
        setIsLoading(true)
        const ipAddress = localStorage.getItem('userIP');
        let url = '/api/getCarListUsingApi?brand=' + location.state.brand + '&series=' + location.state.series + '&selectedMinYear=' + event.target.value + '&selectedMaxYear=' + selectedMaxYear + '&selectedMinOdo=' + selectedMinOdo + '&selectedMaxOdo=' + selectedMaxOdo + '&resultsPerPage=25' + '&preset=0' + '&dailyDeals=' + location.state.dailyDeals + '&ipAddress=' + ipAddress;
        if (selectedTrim != null) {
            url = url + '&trimId=' + location.state.trimsIdMap[selectedTrim];
        }
        axios
            .get(url)
            .then(response =>
                setCars(response.data.cars)
            ).then(response => {
            setIsLoading(false)
        })
            .catch(error => {
                setOpenModal(true)
                setIsLoading(false)
            });
    };

    const maxYearChange = event => {
        setSelectedMaxYear(event.target.value);
        setIsLoading(true)
        const ipAddress = localStorage.getItem('userIP');
        let url = '/api/getCarListUsingApi?brand=' + location.state.brand + '&series=' + location.state.series + '&selectedMinYear=' + selectedMinYear + '&selectedMaxYear=' + event.target.value + '&selectedMinOdo=' + selectedMinOdo + '&selectedMaxOdo=' + selectedMaxOdo + '&resultsPerPage=25' + '&preset=0' + '&dailyDeals=' + location.state.dailyDeals + '&ipAddress=' + ipAddress;
        if (selectedTrim != null) {
            url = url + '&trimId=' + location.state.trimsIdMap[selectedTrim];
        }
        axios
            .get(url)
            .then(response =>
                setCars(response.data.cars)
            ).then(response => {
            setIsLoading(false)
        })
            .catch(error => {
                setOpenModal(true)
                setIsLoading(false)
            });
    };

    const minOdoChange = event => {
        setSelectedMinOdo(event.target.value);
        setIsLoading(true)
        const ipAddress = localStorage.getItem('userIP');
        let url = '/api/getCarListUsingApi?brand=' + location.state.brand + '&series=' + location.state.series + '&selectedMinYear=' + selectedMinYear + '&selectedMaxYear=' + selectedMaxYear + '&selectedMinOdo=' + event.target.value + '&selectedMaxOdo=' + selectedMaxOdo + '&resultsPerPage=25' + '&preset=0' + '&dailyDeals=' + location.state.dailyDeals + '&ipAddress=' + ipAddress;
        if (selectedTrim != null) {
            url = url + '&trimId=' + location.state.trimsIdMap[selectedTrim];
        }
        axios
            .get(url)
            .then(response =>
                setCars(response.data.cars)
            ).then(response => {
            setIsLoading(false)
        })
            .catch(error => {
                setOpenModal(true)
                setIsLoading(false)
            });
    };

    const maxOdoChange = event => {
        setSelectedMaxOdo(event.target.value);
        setIsLoading(true)
        const ipAddress = localStorage.getItem('userIP');
        let url = '/api/getCarListUsingApi?brand=' + location.state.brand + '&series=' + location.state.series + '&selectedMinYear=' + selectedMinYear + '&selectedMaxYear=' + selectedMaxYear + '&selectedMinOdo=' + selectedMinOdo + '&selectedMaxOdo=' + event.target.value + '&resultsPerPage=25' + '&preset=0' + '&dailyDeals=' + location.state.dailyDeals + '&ipAddress=' + ipAddress;
        if (selectedTrim != null) {
            url = url + '&trimId=' + location.state.trimsIdMap[selectedTrim];
        }
        axios
            .get(url)
            .then(response =>
                setCars(response.data.cars)
            ).then(response => {
            setIsLoading(false)
        })
            .catch(error => {
                setOpenModal(true)
                setIsLoading(false)
            });
    };

    function closeInputModalAndOpenAlertModal() {
        setOpenModalWithInput(false)
        setOpenAlertModal(true)
        setInterestedCarVin(null)
    }

    function closeModal() {
        setInterestedCarVin(null);
        setOpenModalWithInput(false)
        console.log("Closing!" + openModalWithInput)
    }

    function saveCar(car) {
        setIsLoading(true);
        axios
            .post("/api/saveCar", car, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                setIsLoading(false);
                if (response.status === 200) {
                    setSavedCars(prevSavedCars => new Set(prevSavedCars).add(car.vin));
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }

    return (
        <body style={{minHeight: '1300px'}}>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        {isLoading ? <LoadingSpinner/> : ""}
        <Helmet>
            <title>Search Results</title>
        </Helmet>
        <Modal
            open={openModal}
            content={t('search.carSold')}
            onClose={() => setOpenModal(false)}/>
        <div class="windowLeft">
            <h1 class="title">VEHICLES</h1>
            <div className="chipDiv">
                <div className="defaultChip">
                    <div className="chip">
                        <p>{location.state.brand}</p>
                    </div>
                    <div className="chip">
                        <p>{location.state.series}</p>
                    </div>
                </div>
                <div className="adjustableChip">
                    {
                        location.state.minYear !== null ? (
                            <div className="chip">
                                <p>{t('search.minYear')}: {location.state.minYear}</p>
                            </div>) : (<div/>)
                    }
                    {
                        location.state.maxYear !== null ? (
                            <div className="chip">
                                <p>{t('search.maxYear')}: {location.state.maxYear}</p>
                            </div>) : (<div/>)
                    }
                    {
                        location.state.minOdo !== null ? (
                            <div className="chip">
                                <p>{t('search.minOdm')}: {location.state.minOdo}K {t('search.miles')}</p>
                            </div>) : (<div/>)
                    }
                    {
                        location.state.maxOdo !== null ? (
                            <div className="chip">
                                <p>{t('search.maxOdm')}: {location.state.maxOdo}K {t('search.miles')}</p>
                            </div>) : (<div/>)
                    }
                </div>
            </div>

            <div className={"refineSearchDiv"}>
                <div className={"trimDiv"}>
                    <h2>Trim</h2>
                    <select value={selectedTrim} onChange={onChangeOfTrim} name="trim" id="trim"
                            className="trimDropdown">
                        {Object.keys(location.state.trimsCountMap).map(key => (
                            <option key={key} value={key}>
                                {key} ({location.state.trimsCountMap[key]})
                            </option>
                        ))}
                    </select>
                </div>
                <div className={"yearDiv"}>
                    <h2>{t('search.year')}</h2>
                    <div className={"refineSearchDropdownDiv"}>
                        <select value={selectedMinYear} onChange={minYearChange} name="minYear" id="minYear"
                                className="trimDropdown" placeholder={parseInt(location.state.minYear)}>
                            {_.range(1900, parseInt(selectedMaxYear) + 1).map(value => <option key={value}
                                                                                               value={value}>{value}</option>)}
                        </select>
                        <select value={selectedMaxYear} onChange={maxYearChange} name="maxYear" id="maxYear"
                                className="trimDropdown" placeholder={parseInt(location.state.maxYear)}>
                            {_.range(parseInt(selectedMinYear), 2025).map(value => <option key={value}
                                                                                           value={value}>{value}</option>)}
                        </select>
                    </div>
                </div>
                <div className={"odoDiv"}>
                    <h2>{t('details.odometer')}</h2>
                    <div className={"refineSearchDropdownDiv"}>
                        <select value={selectedMinOdo} onChange={minOdoChange} name="minOdo" id="minOdo"
                                className="trimDropdown" placeholder={parseInt(location.state.minOdo)}>
                            {_.range(0, parseInt(selectedMaxOdo) + 10, 10).map(value => <option key={value}
                                                                                                value={value}>{value}K
                                miles</option>)}
                        </select>
                        <select value={selectedMaxOdo} onChange={maxOdoChange} name="maxOdo" id="maxOdo"
                                className="trimDropdown" placeholder={parseInt(location.state.maxOdo)}>
                            {_.range(parseInt(selectedMinOdo), 140 + 10, 10).map(value => <option key={value}
                                                                                                  value={value}>{value}K
                                miles</option>)}
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="windowRight">
            <h1 className="title">{t('search.searchResults')}</h1>
            {
                currentTableData.map((car) => (
                    <div className="divForEachCar">
                        <ModalWithInput
                            open={interestedCarVin === car.vin}
                            onClose={() => closeInputModalAndOpenAlertModal()}
                            onDirectClose={() => closeModal()}
                            vin={car.vin}/>
                        <Modal
                            open={openAlertModal}
                            content={t('search.submitted')}
                            onClose={() => setOpenAlertModal(false)}/>
                        <div className="divLeft">
                            <img className="carImage" onClick={() => goToDetails(car.vin)}
                                 src={car.imgSrc}/>
                        </div>
                        <div className="divRight">
                            <div className={"divInsideRight"}>
                                <div className={"insideLeft"}>
                                    <h2 onClick={() => goToDetails(car.vin)} className="searchDivTitle"
                                        id="titleOfCar">{car.carName}</h2>
                                    <h4 id="milesOfCar">{car.odometer} {t('search.miles')}</h4>
                                    <p id="drivenChainOfCar">{car.driveChain}</p>
                                    <p id="engineOfCar">{car.engineInfo}</p>
                                    <p id="exteriorColorOfCar">{t('search.exteriorColor')} {car.exteriorColor}</p>
                                    <p id="interiorColorOfCar">{t('search.interiorColor')} {car.interiorColor}</p>
                                    <p id="locationState">{t('search.locate')} {car.locatedState}</p>
                                    <div className={"gradeDiv"}>
                                        <p id="conditionGrade">{t('search.conditionGrade')} {car.conditionGrade}/5</p>
                                        <div className="infoLink">
                                            <span className="tooltipText">{t('search.reachToUs')}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={"insideRight"}>
                                    <h3 id="price">{t('details.price')}: $ {car.price}</h3>
                                    <div className={"insideRightButtons"}>
                                        <button onClick={() => openModalWithInputFunction(car.vin)}
                                                className={"interestedButton"}>{t('search.interested')}</button>
                                        {isAuthenticated ? (
                                            savedCars.has(car.vin) ? (
                                                <button className="saveButton" disabled>{t('search.saved')}</button>
                                            ) : (
                                                <button className="saveButton"
                                                        onClick={() => saveCar(car)}>{t('search.save')}</button>
                                            )
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
        <CustomPagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={cars.length}
            pageSize={itemsPerPage}
            onPageChange={page => setCurrentPage(page)}
        />
        </body>
    );
}

export default SearchResults;