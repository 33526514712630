// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    const login = () => {
        setIsAuthenticated(true);
    };
    const verifyAuth = async () => {
        try {
            const response = await axios.get('/user/verifyAuth', {
                params: {
                    _: new Date().getTime(),  // cache-busting query parameter
                },
            });
            console.log("is authenticated from AuthProvider " + response.status)
            if (response.status === 200) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
        } catch (error) {
            console.error('Failed to verify auth:', error);
            setIsAuthenticated(false);
        }
    };

    useEffect(() => {
        verifyAuth();  // Automatically check auth status when the component mounts
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, verifyAuth, login }}>
            {children}
        </AuthContext.Provider>
    );
};
