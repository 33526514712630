import React, { useState } from "react";
import "./RegisterPage.css";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const RegisterPage = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);  // State for error message
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);  // Clear any previous error message on new submission

        if (!email.trim() || !password.trim() || !fullName.trim()) {
            setError('Email, password, and full name are required.');
            return;
        }

        // Ensure password and confirmPassword match
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const response = await axios.post('/user/register', {
                "fullName": fullName,
                "email":  email,
                "password": password,
                "role":  "USER"
            });
            if (response.status === 200) {
                // Registration successful, redirect to /myProfile
                navigate("/myProfile");
            }
        } catch (error) {
            console.error('Registration failed:', error.response ? error.response.data : error.message);
            setError('Registration failed. Please try again!');
        }
    };

    return (
        <>
            <div className="register-page">
                <div className="register-outter-div">
                    <p className={"titleP"}>
                        Register to save your favorite cars, contact us about your interests and more!
                    </p>
                    <form className="register-form" onSubmit={handleSubmit}>
                        <input
                            type="text"
                            placeholder="Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Full Name"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                        <button type="submit">Register Account</button>
                        {error && <div className="error-message">{error}</div>}
                        <div className={"googleLogin"}>
                            <GoogleLogin
                                onSuccess={credentialResponse => {
                                    console.log(credentialResponse);
                                    axios
                                        .post('/user/postGoogleSignIn', {
                                            params: {
                                                credential: credentialResponse.credential
                                            }
                                        })
                                        .then(response =>
                                            navigate("/myProfile", {state: response.data})
                                        ).then(response => {

                                    })
                                        .catch(error => {

                                        });
                                }}

                                onError={() => {
                                    console.log('Login Failed');
                                }}

                            />
                        </div>
                        <p className="message">Already registered? <a href="/login">Sign In</a></p>
                    </form>
                </div>
            </div>
        </>
    );
};

export default RegisterPage;