import React, {useMemo, useState} from 'react';
import Dropdown from "./Dropdown";
import {useTranslation} from "react-i18next";
import "./Home.css";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import { Helmet } from 'react-helmet';
import CookieBanner from "./CookieBanner";


const Home = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);


    useMemo(() => {
        axios.get('https://geolocation-db.com/json/')
            .then(response => {
                addVisit(response.data.IPv4)
            }, []);
    })

    function addVisit(ip) {
        localStorage.setItem('userIP', ip);
        let url = '/api/addVisit?ipAddress=' + ip
        axios.post(url);
    }

    const turnOnNoResultText = () => {
        document.getElementById("noResultText").style.display = "block";
    }

    const turnOffNoResultText = () => {
        document.getElementById("noResultText").style.display = "none";
    }

    function searchPresetCar(brand, series) {
        setIsLoading(true);
        turnOffNoResultText()
        // let url = '/api/premiumSearch?brand=' + brand + '&series=' + series + '&zipcode=0&miles=1000&resultsPerPage=25&preset=1&selectedMinYear=1900&selectedMaxYear=1900&selectedMinOdo=0&selectedMaxOdo=0&dailyDeals=-1'
        const ipAddress = localStorage.getItem('userIP');
        let url = '/api/getCarListUsingApi?brand=' + brand + '&series=' + series + '&resultsPerPage=25&preset=1&dailyDeals=-1&ipAddress=' + ipAddress;
        axios
            .get(url)
            .then(response =>
                navigate("/searchResults", {state: response.data})
            ).then(response => {
            setIsLoading(false)
        })
            .catch(error => {
                setIsLoading(false)
                turnOnNoResultText()
            });
    }

    const searchDailyDeals = () => {
        setIsLoading(true);
        turnOffNoResultText()
        axios
            .get('/api/getDailyDeals', {
                params: {
                    limit: 100
                }
            })
            .then(response => {
                setIsLoading(false)
                    navigate("/searchResults", {state: response.data})
                }
            )
            .catch(error => {
                setIsLoading(false)
                turnOnNoResultText()
            });
    }

    return (
        <>
            <body>
            <Helmet>
                <title>Better Us Auto</title>
            </Helmet>
            {isLoading ? <LoadingSpinner/> : ""}
            <h1 id="mainTitle">
                {t('home.mainTitle')}
            </h1>
            <h2 id="subTitle">
                {t('home.searchNearYou')}
            </h2>
            <Dropdown/>
            <h2 id="subTitle">{t('home.topSearch')}</h2>
            <div className="cardView">
                <div className="card" onClick={() => searchPresetCar("BMW", "3 Series")}>
                    <img src="bmw3.jpg" alt="Avatar" className="cardImg"/>
                    <div className="container">
                        <h4><b>BMW 3 Series</b></h4>
                    </div>
                </div>
                <div className="card" onClick={() => searchPresetCar("Toyota","Camry")}>
                    <img src="camry.jpg" alt="Avatar" className="cardImg"/>
                    <div className="container">
                        <h4><b>Toyota Camry</b></h4>
                    </div>
                </div>
                <div className="card" onClick={() => searchPresetCar("Audi", "A5")}>
                    <img src="audia5.webp" alt="Avatar" className="cardImg"/>
                    <div className="container">
                        <h4><b>Audi A5</b></h4>
                    </div>
                </div>
            </div>
            </body>
        </>
    )
};

export default Home;