import React, {useState} from 'react'
import {useTranslation, Trans} from 'react-i18next';
import './Footer.css'

import Modal from "./Modal";

function Footer() {
    const {i18n} = useTranslation();
    const {t} = useTranslation();

    const [openModal, setOpenModal] = useState(false);

    function navigateHome() {
        setOpenModal(true)
    }

    return (
        <div>
            <div id="footer">
                <Modal
                    open={openModal}
                    content={t('home.aboutDetail')}
                    onClose={() => setOpenModal(false)} />
                <div className={"buttonDiv"}>
                    <button className={"footerAbout"} onClick={navigateHome}>{t('home.aboutUs')}</button>
                </div>
            </div>
        </div>
    )
}

export default Footer