import React, {useContext, useEffect, useState} from "react";
import "./CarDetails.css";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import {Helmet} from "react-helmet";
import ModalWithInput from "./ModalWithInput";
import Modal from "./Modal";
import {AuthContext} from "./AuthContext";
import axios from "axios";

const CarDetails = () => {
    const location = useLocation();
    const {t} = useTranslation();
    const [openModalWithInput, setOpenModalWithInput] = useState(false);
    const [openAlertModal, setOpenAlertModal] = useState(false);
    const images = location.state.canvas.map(original => ({ original, thumbnail: `${original}?size=w86h64`}));
    const {isAuthenticated} = useContext(AuthContext);
    const [savedCars, setSavedCars] = useState(new Set());

    useEffect(() => {
        console.log('isAuthenticated changed from CarDetails:', isAuthenticated);
    }, [isAuthenticated]);

    function openModal() {
        setOpenModalWithInput(true)
    }

    function closeInputModalAndOpenAlertModal() {
        setOpenModalWithInput(false)
        setOpenAlertModal(true)
    }

    // function saveCar(car) {
    //     setIsLoading(true);
    //     axios
    //         .post("/api/saveCar", car, {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         })
    //         .then(response => {
    //             setIsLoading(false);
    //             if (response.status === 200) {
    //                 setSavedCars(prevSavedCars => new Set(prevSavedCars).add(car.vin));
    //             }
    //         })
    //         .catch(error => {
    //             setIsLoading(false);
    //         });
    // }

    return (
        <body>
        <Helmet>
            <title>Search Results</title>
        </Helmet>
        <ModalWithInput
            open={openModalWithInput}
            onClose={() => closeInputModalAndOpenAlertModal()}
            onDirectClose={() => setOpenModalWithInput(false)}
            vin = {location.state.vin}/>
        <Modal
            open={openAlertModal}
            content={t('search.submitted')}
            onClose={() => setOpenAlertModal(false)}/>
        <div class="title">
            <h1>{location.state.titleOfTheCar}</h1>
        </div>
        <div class="imageSec">
            <div class="block">
                <section>
                    <ImageGallery
                        items={images}
                        showFullscreenButton={false}
                    />
                </section>
            </div>
        </div>
        <div className={"introAndInterestedButton"}>
            <div className="blockIntro">
                <ul className="briefIntro">
                    <li className="briefIntroLi topLi">{t('details.odometer')}: {location.state.odometer} miles</li>
                    <li className="briefIntroLi">{t('details.driveChain')}: {location.state.driveChain}</li>
                    <li className="briefIntroLi">{t('details.engine')}: {location.state.engineInfo}</li>
                    <li className="briefIntroLi">{t('details.interiorType')}: {location.state.interiorType}</li>
                    <li className="briefIntroLi">Stock ID: {location.state.stockID}</li>
                    <li className="briefIntroLi">{t('search.locate')}: {location.state.locatedState}</li>
                </ul>
            </div>
            <div className="blockButton">
                <h2> Price: ${location.state.price}</h2>
                <button onClick={openModal} className={"interestedButton"}>Interested?</button>
                {/*{isAuthenticated ? (*/}
                {/*    savedCars.has(car.vin) ? (*/}
                {/*        <button className="saveButton" disabled>{t('search.saved')}</button>*/}
                {/*    ) : (*/}
                {/*        <button className="saveButton"*/}
                {/*                onClick={() => saveCar(car)}>{t('search.save')}</button>*/}
                {/*    )*/}
                {/*) : (*/}
                {/*    <>*/}
                {/*    </>*/}
                {/*)}*/}
            </div>
        </div>
        <div class="detailDiv">
            <div class="overview">
                <div className={"gradeDiv"}>
                    <h1>{t('details.overview')}</h1>

                    <div className="infoLink">
                        <span className="tooltipText">{t('search.reachToUs')}</span>
                    </div>
                </div>
                <ul className="detailsDictUl">
                    {Object.entries(location.state.overviewDict).map(([key, value]) => (
                        <div>
                            <li className="detailsDictLi"><b>{key}</b>: {value}</li>
                        </div>
                    ))
                    }
                </ul>
            </div>
            <div class="manufacturerPackageInformation">
                <h1>{t('details.MPInfo')}</h1>
                {
                    Object.entries(location.state.manufacturerPackageInformationDict).map(([key, value]) => (
                        <div className={"packageInfoDiv"}>
                            <h4>{key}</h4>
                            {
                                value.map(details =>
                                    <li className="detailsDictLi">{details}</li>
                                )
                            }
                        </div>
                    ))
                }
            </div>
            <div className="safetyPackageInformation">
                <h1>{t('details.SPInfo')}</h1>
                <ul className="detailsDictUl">
                    {
                        location.state.safetyPackageInformation && location.state.safetyPackageInformation.map(option =>
                            <li className="detailsDictLi">{option}</li>
                        )
                    }
                </ul>
            </div>
            <div className="interiorOptionsInformation">
                <h1>{t('details.IPInfo')}</h1>
                <ul className="detailsDictUl">
                    {
                        location.state.interiorOptions && location.state.interiorOptions.map(option =>
                            <li className="detailsDictLi">{option}</li>
                        )
                    }
                </ul>
            </div>
            <div className="exteriorOptionsInformation">
                <h1>{t('details.EPInfo')}</h1>
                <ul className="detailsDictUl">
                    {
                        location.state.exteriorOptions && location.state.exteriorOptions.map(option =>
                            <li className="detailsDictLi">{option}</li>
                        )
                    }
                </ul>
            </div>
            <div className="infotainmentOptionsInformation">
                <h1>{t('details.IFInfo')}</h1>
                <ul className="detailsDictUl">
                    {
                        location.state.infotainmentOptions && location.state.infotainmentOptions.map(option =>
                            <li className="detailsDictLi">{option}</li>
                        )
                    }
                </ul>
            </div>
            <div className="otherOptionsInformation">
                <h1>{t('details.OPInfo')}</h1>
                <ul className="detailsDictUl">
                    {
                        location.state.otherOptions && location.state.otherOptions.map(option =>
                            <li className="detailsDictLi">{option}</li>
                        )
                    }
                </ul>
            </div>
            <div className="comments">
                <h1>{t('details.comments')}</h1>
                <p>{location.state.sellerComment}</p>
            </div>
        </div>
        </body>
    );
}

export default CarDetails;